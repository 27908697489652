/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-07",
    versionChannel: "nightly",
    buildDate: "2023-09-07T00:20:20.983Z",
    commitHash: "9c7eb90d69fc228b631fccee50045365313f1f9f",
};
